<!-- because of flow change this component is not in use -->
<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        <v-textarea
          v-model="comment"
          class="mt-8"
          outlined
          :rows="3"
          auto-grow
          label="Komentarz"
          placeholder="Wpisz komentarz"
        />
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        type="submit"
        :loading="isProcessing"
        v-shortkey="{enter: ['enter']}"
        @shortkey.native="addComment()"
        @click="addComment()"
      >
        Dodaj komentarz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data: () => ({
    comment: undefined,
  }),
  computed: {
    ...mapState({
      item: state => state.layout.dialog.item,
      isProcessing: state => state.tasks.isProcessing
    }),
  },
  methods: {
    ...mapActions({
      editTask: 'tasks/editItem',
      closeDialog: 'layout/closeDialog',
    }),
    addComment () {
      this.editTask({
        tableName: 'tasks',
        params: { comment: this.comment },
        id: this.item.id,
        notWebSocketHandled: true
      }).then(() => {
        this.closeDialog()
      })
    }
  }
}
</script>
